export default class ScrollService {
  constructor() {
    this.currentScrollTop = 0;
    this.body = document.querySelector('body');
  }

  disableScroll() {
    if (this.body.classList.contains('noscroll')) return;
    this.currentScrollTop = window.scrollY;
    this.body.classList.add('noscroll');
  }

  enableScroll() {
    if (!this.body.classList.contains('noscroll')) return;
    this.body.classList.remove('noscroll');
    window.scrollTo(0, this.currentScrollTop);
  }
}
