import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import ClickableDiv from '../../../ui_kit/clickable_div/ClickableDiv';
import arrowIcon from '../../../../images/icons/chevron-up.svg';
import arrowIconDesktop from '../../../../images/icons/chevron-up-desktop.svg';
import styles from './Scroller.module.sass';

const DESKTOP_WIDTH_BREAKPOINT = 850;

function scrollToTop() {
  smoothscroll.polyfill();
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

function Scroller() {
  return (
    <ClickableDiv className={styles.scroller} onClick={scrollToTop}>
      <picture>
        <source srcSet={arrowIcon} media={`(max-width: ${DESKTOP_WIDTH_BREAKPOINT}px)`} />
        <source srcSet={arrowIconDesktop} />
        <img loading="lazy" src={arrowIcon} alt="scroll to top" />
      </picture>
    </ClickableDiv>
  );
}

export default Scroller;
