import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Cookies from 'js-cookie';
import loadable from '../../../loadable';
import menuIcon from '../../../../images/icons/menu.svg';
import logo from '../../../../images/logo/header-logo.svg';
import { orderActions } from '../../../checkout/actions';
import styles from './Header.module.sass';
import SearchAndCart from './search_and_cart/SearchAndCart';
import { orderSelector } from '../../../checkout/selectors/order';
import { userObjectSelector } from '../../../user/selectors/user';
import Categories from '../categories/Categories';
import Login from './login/Login';
import ClickableDiv from '../../../ui_kit/clickable_div/ClickableDiv';
import { modalsActions } from '../../../modals/actions';
import MODALS from '../../../modals/constants/modals';
import HeaderContext from '../../context';
import { taxonsActions } from '../../../cart/actions';
import { taxonsSelector } from '../../../cart/selectors/taxons';
import Category from '../categories/category/Category';
import DROPDOWNS from '../../constants/dropdowns';

const MobileMenu = loadable(() => import('../mobile_menu/MobileMenu'));

class Header extends Component {
  bannerHeight = typeof document !== 'undefined' ? Math.max(
    ...Array.from(document.querySelectorAll('.banner-top-container')).map((element) => element.scrollHeight),
  ) : 0;

  body = typeof document !== 'undefined' ? document.body : null;

  constructor(props) {
    super(props);

    const { lineItemsCount } = props;

    this.state = {
      state: 'normal',
      showMobileMenu: false,
      currentDropdown: null,
      dropdownCloseTimeout: null,
      defaultLineItemsCount: lineItemsCount,
    };
  }

  componentDidMount() {
    const { orderLoadRequest, taxonsLoadRequest } = this.props;
    orderLoadRequest();
    taxonsLoadRequest();

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    const { defaultLineItemsCount } = this.state;
    if (!defaultLineItemsCount) return;

    const { order } = this.props;

    if (!order.id) { return; }

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      defaultLineItemsCount: null,
    });
  }

  handleScroll = () => {
    if (window.scrollY > this.bannerHeight && !this.body.classList.contains('noscroll')) {
      this.setState({ state: 'sticky' });
    } else {
      this.setState({ state: 'normal' });
    }
  };

  onMobileMenuOpenClick = () => {
    this.setState({ showMobileMenu: true });
  };

  onMobileMenuCloseClick = () => {
    this.setState({ showMobileMenu: false });
  };

  onDropdownLinkMouseOver = (id) => {
    const { dropdownCloseTimeout } = this.state;

    clearTimeout(dropdownCloseTimeout);

    this.setState({
      currentDropdown: id,
      dropdownCloseTimeout: null,
    });
  };

  onDropdownMouseOver = () => {
    const { dropdownCloseTimeout } = this.state;

    clearTimeout(dropdownCloseTimeout);

    this.setState({
      dropdownCloseTimeout: null,
    });
  };

  onDropdownLinkMouseOut = () => {
    const dropdownCloseTimeout = setTimeout(() => {
      this.setState({
        currentDropdown: null,
      });
    }, 2000);

    this.setState({
      dropdownCloseTimeout,
    });
  };

  onDropdownMouseOut = () => {
    const dropdownCloseTimeout = setTimeout(() => {
      this.setState({
        currentDropdown: null,
      });
    }, 100);

    this.setState({
      dropdownCloseTimeout,
    });
  };

  openLoginDialog = () => {
    const { openLoginDialog } = this.props;
    Cookies.set('signed_up_from', 'menu_signup');
    window.userLoggedInCallback = null;
    openLoginDialog();
  };

  logout = () => {
    const { logout } = this.props;
    logout();
  };

  render() {
    const {
      state, showMobileMenu, currentDropdown, defaultLineItemsCount,
    } = this.state;
    const {
      taxons, user, shopImageLink, shopImage, whiteBackground,
    } = this.props;

    return (
      <HeaderContext.Provider value={this.props}>
        <header className={cx(styles.header, styles[state])}>
          <div className={styles.content}>
            <div className={`${styles.block} ${styles.mobile}`}>
              <ClickableDiv
                className={styles['mobile-bar-icon']}
                onClick={this.onMobileMenuOpenClick}
              >
                <img src={menuIcon} alt="menu icon" />
              </ClickableDiv>
            </div>
            <div className={styles.block}>
              <div className={styles.logo}>
                <a href="/">
                  <img src={logo} alt="logo" />
                </a>
              </div>
            </div>
            <div className={`${styles.block} ${styles.desktop}`}>
              <Categories
                taxons={taxons}
                sticky={state === 'sticky'}
                currentDropdown={currentDropdown}
                shopImage={shopImage}
                shopImageLink={shopImageLink}
                onCategoryMouseOver={this.onDropdownLinkMouseOver}
                onCategoryMouseOut={this.onDropdownLinkMouseOut}
                onDropdownMouseOver={this.onDropdownMouseOver}
                onDropdownMouseOut={this.onDropdownMouseOut}
                whiteBackground={whiteBackground}
              />
            </div>
            <div className={styles.block}>
              <div className={styles.desktop}>
                <Login
                  user={user}
                  currentDropdown={currentDropdown}
                  onDropdownLinkMouseOver={this.onDropdownLinkMouseOver}
                  onDropdownLinkMouseOut={this.onDropdownLinkMouseOut}
                  onDropdownMouseOver={this.onDropdownMouseOver}
                  onDropdownMouseOut={this.onDropdownMouseOut}
                  openLoginDialog={this.openLoginDialog}
                  whiteBackground={whiteBackground}
                  sticky={state === 'sticky'}
                />
              </div>
              <SearchAndCart defaultLineItemsCount={defaultLineItemsCount} />
              <div className={`${styles.rewards} ${styles.desktop}`}>
                <Category
                  url={user ? `/users/${user.id}/rewards/` : '/pages/the_lounge/'}
                  title="Rewards"
                  id={DROPDOWNS.REWARDS}
                  active={currentDropdown === DROPDOWNS.REWARDS}
                  sticky={state === 'sticky'}
                  onComponentMouseOver={this.onDropdownLinkMouseOver}
                  onComponentMouseOut={this.onDropdownLinkMouseOut}
                  whiteBackground={whiteBackground}
                />
              </div>
            </div>
          </div>
        </header>
        { showMobileMenu && (
          <MobileMenu
            taxons={taxons}
            user={user}
            onMobileMenuCloseClick={this.onMobileMenuCloseClick}
            openLoginDialog={this.openLoginDialog}
            logout={this.logout}
            defaultLineItemsCount={defaultLineItemsCount}
            className={styles.mobile}
          />
        )}
      </HeaderContext.Provider>
    );
  }
}

Header.propTypes = {
  orderLoadRequest: PropTypes.func.isRequired,
  taxonsLoadRequest: PropTypes.func.isRequired,
  taxons: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  openLoginDialog: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  shopImage: PropTypes.string.isRequired,
  shopImageLink: PropTypes.string.isRequired,
  whiteBackground: PropTypes.bool,
  lineItemsCount: PropTypes.number.isRequired,
  order: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  showLoyaltyProgram: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  taxons: null,
  user: null,
  whiteBackground: false,
};

const mapStateToProps = (state) => ({
  taxons: taxonsSelector(state),
  user: userObjectSelector(state),
  order: orderSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  orderLoadRequest: () => dispatch(orderActions.orderLoadRequest()),
  taxonsLoadRequest: () => dispatch(taxonsActions.taxonsLoadRequest()),
  openLoginDialog: () => dispatch(modalsActions.openModalRequest({ modal: MODALS.SIGN_IN })),
  logout: () => dispatch(modalsActions.logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
