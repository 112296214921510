import React from 'react';
import Scroller from '../scroller/Scroller';
import SocialLinks from '../social_links/SocialLinks';
import Link from '../../../header/components/mobile_menu/link/Link';
import Copyright from '../copyright/Copyright';
import styles from './Footer.module.sass';

function Footer() {
  return (
    <div className={styles['footer-wrapper']}>
      <div className={styles['footer-container']}>
        <SocialLinks />
        <div className={styles['links-container']}>
          <Link className={styles.link} title="SAFE SCIENCE" href="/pages/about_us/" />
          <Link className={styles.link} title="CONTACT US" href="/pages/contact_us/" />
          <Link className={styles.link} title="PRESS" href="/press/" />
          <Link className={styles.link} title="TERMS OF USE" href="/pages/terms_and_conditions/" />
          <Link className={styles.link} title="PRIVACY POLICY" href="/pages/privacy_policy/" />
          <Link className={styles.link} title="Affiliates" href="/pages/affiliate/" />
        </div>
        <Scroller />
      </div>
      <Copyright />
    </div>
  );
}

export default Footer;
