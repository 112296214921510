import { createSelector } from 'reselect';

export const cartSelector = (state) => state.cart;

export const cartActiveSelector = createSelector(
  cartSelector,
  (cart) => cart.active,
);

export const couponCodeSelector = createSelector(
  cartSelector,
  (cart) => cart.couponCode,
);

export const couponCodeErrorTextSelector = createSelector(
  couponCodeSelector,
  (couponCode) => couponCode.errorText,
);

export const couponCodeSuccessTextSelector = createSelector(
  couponCodeSelector,
  (couponCode) => couponCode.successText,
);
