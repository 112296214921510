import { getFormValues, isInvalid } from 'redux-form';

export const signInFormValuesSelector = (state) => getFormValues('signIn')(state);
export const signInFormInvalidSelector = (state) => isInvalid('signIn')(state);
export const emailFormValuesSelector = (state) => getFormValues('email')(state);
export const emailFormInvalidSelector = (state) => isInvalid('email')(state);
export const extendedSignUpFormValuesSelector = (state) => getFormValues('extendedSignUp')(state);
export const extendedSignUpFormInvalidSelector = (state) => isInvalid('extendedSignUp')(state);
export const errorSelector = (state) => state.modals.error;
export const loadingSelector = (state) => state.modals.loading;
export const dataSelector = (state) => state.modals.data;
export const currentModalSelector = (state) => state.modals.currentModal;
