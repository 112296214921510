import React from 'react';
import styles from './Copyright.module.sass';

function Copyright() {
  return (
    <div className={styles.copyright}>
      {`© ${new Date().getFullYear()} Volition Beauty. All Rights Reserved. This site is intended for US consumers. Cookies and related technology are used for advertising. To learn more or opt-out, visit `}
      <a href="https://optout.networkadvertising.org/?c=1" target="_blank" rel="noreferrer">AdChoices</a>
      {' and our '}
      <a href="/pages/privacy_policy/" target="_blank">Privacy Policy</a>
      .
    </div>
  );
}

export default Copyright;
