import { createSelector } from 'reselect';
import { getFormValues, isInvalid, formValueSelector } from 'redux-form';

export const userSelector = (state) => state.user;

export const userObjectSelector = createSelector(
  userSelector,
  (user) => user.object,
);

export const descriptionLoadingSelector = createSelector(
  userSelector,
  (user) => user.descriptionLoading,
);

export const descriptionEditModeSelector = createSelector(
  userSelector,
  (user) => user.descriptionEditMode,
);

export const accountInformationEditModeSelector = createSelector(
  userSelector,
  (user) => user.accountInformationEditMode,
);

export const addressBookEditModeSelector = createSelector(
  userSelector,
  (user) => user.addressBookEditMode,
);

export const newsletterSubscriptionEditModeSelector = createSelector(
  userSelector,
  (user) => user.newsletterSubscriptionEditMode,
);

export const newsletterSubscriptionLoadingSelector = createSelector(
  userSelector,
  (user) => user.newsletterSubscriptionLoading,
);

export const passwordEditModeSelector = createSelector(
  userSelector,
  (user) => user.passwordEditMode,
);

export const passwordLoadingSelector = createSelector(
  userSelector,
  (user) => user.passwordLoading,
);

export const paymentEditModeSelector = createSelector(
  userSelector,
  (user) => user.paymentEditMode,
);

export const imageLoadingSelector = createSelector(
  userSelector,
  (user) => user.imageLoading,
);

export const accountInformationLoadingSelector = createSelector(
  userSelector,
  (user) => user.accountInformationLoading,
);

export const addressBookLoadingSelector = createSelector(
  userSelector,
  (user) => user.addressBookLoading,
);

export const firstNameSelector = createSelector(
  userObjectSelector,
  (user) => user.first_name,
);

export const paymentSourcesSelector = createSelector(
  userObjectSelector,
  (user) => user && user.payment_sources,
);

export const lastNameSelector = createSelector(
  userObjectSelector,
  (user) => user.last_name,
);

export const phoneSelector = createSelector(
  userObjectSelector,
  (user) => user.phone,
);

export const emailSelector = createSelector(
  userObjectSelector,
  (user) => user.email,
);

export const birthDaySelector = createSelector(
  userObjectSelector,
  (user) => (user.birth_day ? String(user.birth_day) : null),
);

export const birthMonthSelector = createSelector(
  userObjectSelector,
  (user) => (user.birth_month ? String(user.birth_month) : null),
);

export const accountInformationFormValuesSelector = (state) => getFormValues('accountInformation')(state);
export const accountInformationInvalidSelector = (state) => isInvalid('accountInformation')(state);

export const shipAddressFormValuesSelector = (state) => getFormValues('shipAddress')(state);
export const shipAddressInvalidSelector = (state) => isInvalid('shipAddress')(state);

const shipAddressFormSelector = formValueSelector('shipAddress');
export const shipAddressSelectedCountryIdSelector = (state) => shipAddressFormSelector(state, 'country_id') && Number(shipAddressFormSelector(state, 'country_id'));
export const shipAddressStateIdSelector = (state) => {
  const stateId = shipAddressFormSelector(state, 'state_id');
  return stateId ? Number(stateId) : null;
};
export const shipAddressSelector = createSelector(
  userObjectSelector,
  (user) => user.ship_address,
);

export const billAddressFormValuesSelector = (state) => getFormValues('billAddress')(state);
export const billAddressInvalidSelector = (state) => isInvalid('billAddress')(state);

const billAddressFormSelector = formValueSelector('billAddress');
export const billAddressSelectedCountryIdSelector = (state) => billAddressFormSelector(state, 'country_id') && Number(billAddressFormSelector(state, 'country_id'));
export const billAddressStateIdSelector = (state) => {
  const stateId = billAddressFormSelector(state, 'state_id');
  return stateId ? Number(stateId) : null;
};
export const billAddressSelector = createSelector(
  userObjectSelector,
  (user) => user.bill_address,
);

export const passwordFormValuesSelector = (state) => getFormValues('password')(state);
export const passwordInvalidSelector = (state) => isInvalid('password')(state);
