import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Category.module.sass';

class Category extends Component {
  onMouseOver = () => {
    const { onComponentMouseOver, id } = this.props;
    onComponentMouseOver(id);
  };

  render() {
    const {
      url, title, onComponentMouseOut, active, sticky, whiteBackground, className,
    } = this.props;
    return (
      <div
        className={cx(
          styles.category,
          active ? styles.active : null,
          sticky ? styles.sticky : null,
          whiteBackground ? styles['white-background'] : null,
          className,
        )}
        onMouseOver={this.onMouseOver}
        onFocus={this.onMouseOver}
        onMouseOut={onComponentMouseOut}
        onBlur={onComponentMouseOut}
      >
        <a href={url}>
          {title}
        </a>
      </div>
    );
  }
}

Category.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onComponentMouseOver: PropTypes.func.isRequired,
  onComponentMouseOut: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  sticky: PropTypes.bool.isRequired,
  whiteBackground: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

Category.defaultProps = {
  className: null,
};

export default Category;
