import loadable from '@loadable/component';

// https://github.com/gregberge/loadable-components/issues/667#issuecomment-929402094

function retry(
  fn,
  { retries = 5, interval = 500, exponentialBackoff = true } = {},
) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retries === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
          }).then(resolve, reject);
        }, interval);
      });
  });
}

function loadableWithRetry(
  loader,
  { retries = 5, interval = 500, exponentialBackoff = true } = {},
) {
  return loadable(() => retry(loader, {
    retries,
    interval,
    exponentialBackoff,
  }));
}

export default loadableWithRetry;
