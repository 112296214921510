import ScrollService from './scroll_service';
import { ensureCookiesEnabled, removeLoginOnCheckoutCookie } from './utils';
import initLazyLoading from '../lazyload';
import renderHeader from '../header';
import renderFooter from '../footer';
import renderModals from '../modals';
import renderTopBanner from '../top_banner';
import Bugsnag from '../bugsnag';

document.addEventListener('DOMContentLoaded', () => {
  Bugsnag.start();
  window.scrollService = new ScrollService();
  ensureCookiesEnabled();
  renderHeader();
  initLazyLoading();
  renderFooter();
  renderModals();
  removeLoginOnCheckoutCookie();
  renderTopBanner();
});
