import React from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.sass';

function Dropdown(props) {
  const { children, onComponentMouseOver, onComponentMouseOut } = props;

  return (
    <div
      className={styles.dropdown}
      onMouseOver={onComponentMouseOver}
      onFocus={onComponentMouseOver}
      onMouseOut={onComponentMouseOut}
      onBlur={onComponentMouseOut}
    >
      <div className={styles.wrapper}>
        {children}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  onComponentMouseOver: PropTypes.func,
  onComponentMouseOut: PropTypes.func,
};

Dropdown.defaultProps = {
  onComponentMouseOver: null,
  onComponentMouseOut: null,
};

export default Dropdown;
