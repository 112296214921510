import { createSelector } from 'reselect';

export const lineItemsSelector = (state) => state.lineItems;
export const lineItemsErrorSelector = createSelector(
  lineItemsSelector,
  (lineItems) => lineItems.error,
);
export const lineItemsObjectsSelector = createSelector(
  lineItemsSelector,
  (lineItems) => lineItems.objects,
);

export const lineItemsCountSelector = createSelector(
  lineItemsObjectsSelector,
  (lineItems) => lineItems.map((lineItem) => lineItem.quantity).reduce((a, b) => a + b, 0),
);
