import React from 'react';
import PropTypes from 'prop-types';
import searchIcon from '../../../../../images/icons/search.svg';
import styles from './Icon.module.sass';
import ClickableDiv from '../../../../ui_kit/clickable_div/ClickableDiv';

function Icon(props) {
  const { onClick } = props;
  return (
    <ClickableDiv
      className={styles.icon}
      onClick={onClick}
    >
      <img src={searchIcon} alt="search icon" />
    </ClickableDiv>
  );
}

Icon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Icon;
