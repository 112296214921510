import React from 'react';
import ReactDOM from 'react-dom';

import Footer from './components/footer/Footer';

export default function renderFooter() {
  const footerElement = document.getElementById('react-footer');

  if (!footerElement) { return; }

  ReactDOM.hydrate(
    <Footer />,
    footerElement,
  );
}
