import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cartIcon from '../../../../images/icons/bag.svg';
import styles from './Bucket.module.sass';
import ClickableDiv from '../../../ui_kit/clickable_div/ClickableDiv';

class Bucket extends Component {
  render() {
    const { openCart, lineItemsCount, innerRef } = this.props;

    return (
      <ClickableDiv
        className={styles.bucket}
        onClick={openCart}
        ref={innerRef}
      >
        <img src={cartIcon} alt="Cart icon" />
        <div className={styles['item-count']}>{lineItemsCount}</div>
      </ClickableDiv>
    );
  }
}

Bucket.propTypes = {
  innerRef: PropTypes.shape({}).isRequired,
  lineItemsCount: PropTypes.number.isRequired,
  openCart: PropTypes.func.isRequired,
};

export default React.forwardRef((props, ref) => <Bucket innerRef={ref} {...props} />);
