const DROPDOWNS = {
  SHOP: 'SHOP',
  BEST_SELLERS: 'BEST SELLERS',
  ABOUT: 'ABOUT',
  ACCOUNT: 'ACCOUNT',
  REGIMEN_FINDER: 'REGIMEN FINDER',
  REWARDS: 'REWARDS',
};

export default DROPDOWNS;
