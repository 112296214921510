import React from 'react';
import instagramIcon from '../../../../images/icons/instagram.svg';
import facebookIcon from '../../../../images/icons/facebook.svg';
import youtubeIcon from '../../../../images/icons/youtube.svg';
import tiktokIcon from '../../../../images/icons/tiktok.svg';
import styles from './SocialLinks.module.sass';

function SocialLinks() {
  return (
    <div className={styles.icons}>
      <a className={styles.icon} href="https://www.instagram.com/volitionbeauty/" target="_blank" rel="noreferrer">
        <img loading="lazy" src={instagramIcon} alt="instagram" />
      </a>
      <a className={styles.icon} href="https://www.facebook.com/volitionbeauty/" target="_blank" rel="noreferrer">
        <img loading="lazy" src={facebookIcon} alt="facebook" />
      </a>
      <a className={styles.icon} href="https://www.youtube.com/channel/UCmnxqP2Enr-9D6ZVON29JMQ/featured" target="_blank" rel="noreferrer">
        <img loading="lazy" src={youtubeIcon} alt="youtube" />
      </a>
      <a className={styles.icon} href="https://www.tiktok.com/@volitionbeauty?lang=en" target="_blank" rel="noreferrer">
        <img loading="lazy" src={tiktokIcon} alt="tiktok" />
      </a>
    </div>
  );
}

export default SocialLinks;
