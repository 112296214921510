import { createSelector } from 'reselect';

export const loyaltyProgramSelector = (state) => state.loyaltyProgram;

export const pointMovementsSelector = createSelector(
  loyaltyProgramSelector,
  (loyaltyProgram) => loyaltyProgram.pointMovements,
);

export const pointsEarnedForYearSelector = createSelector(
  loyaltyProgramSelector,
  (loyaltyProgram) => loyaltyProgram.pointsEarnedForYear,
);

export const tiersSelector = createSelector(
  loyaltyProgramSelector,
  (loyaltyProgram) => loyaltyProgram.tiers,
);
