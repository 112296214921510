const BANNER_CHANGE_INTERVAL = 3000;

export default function renderTopBanner() {
  const banners = Array.from(document.querySelectorAll('.banners-top'));

  banners.forEach((banner) => {
    const contentBanners = Array.from(banner.querySelectorAll('.banner-top-container'));
    let currentBannerIndex = contentBanners.length - 1;

    setInterval(() => {
      contentBanners[currentBannerIndex].classList.add('hide');
      if (currentBannerIndex === contentBanners.length - 1) {
        currentBannerIndex = 0;
      } else {
        currentBannerIndex += 1;
      }
      contentBanners[currentBannerIndex].classList.remove('hide');
    }, BANNER_CHANGE_INTERVAL);
  });
}
