import React from 'react';
import PropTypes from 'prop-types';
import styles from './SearchAndCart.module.sass';
import Cart from '../../../../cart/components/cart/Cart';
import Search from '../../search/Search';

function SearchAndCart(props) {
  const { defaultLineItemsCount } = props;
  return (
    <div className={styles['search-and-cart']}>
      <Search />
      <Cart defaultLineItemsCount={defaultLineItemsCount} />
    </div>
  );
}

SearchAndCart.propTypes = {
  defaultLineItemsCount: PropTypes.number,
};

SearchAndCart.defaultProps = {
  defaultLineItemsCount: null,
};

export default SearchAndCart;
