import Bugsnag from '@bugsnag/js';
import COMMON_CONSTANTS from './common/constants.js.erb';
import { userObjectSelector } from './user/selectors/user';

const API_KEY = 'c0f2bdcf209921f51fa9c5bc379809a7';

const start = () => {
  Bugsnag.start({
    apiKey: API_KEY,
    releaseStage: COMMON_CONSTANTS.ENVIRONMENT,
    onError(event) {
      if (!window.store) { return; }

      const user = userObjectSelector(window.store.getState());
      if (!user) { return; }

      event.setUser(user.id, user.email, user.full_name);
    },
  });
};

export default { start };
