import Cookies from 'js-cookie';

export function ensureCookiesEnabled() {
  function showDisabledCookiesNotice() {
    const noticeBlock = document.querySelector('.cookies-disabled-notice');
    if (noticeBlock) {
      noticeBlock.style.display = 'block';
    }
  }

  if (!navigator.cookieEnabled) {
    showDisabledCookiesNotice();
  }
}

export function removeLoginOnCheckoutCookie() {
  Cookies.remove('login_on_checkout');
}
