import { createSelector } from 'reselect';

export const orderSelector = (state) => state.order;
export const availableCountriesSelector = createSelector(
  orderSelector,
  (order) => order.available_countries,
);
export const orderErrorsSelector = createSelector(
  orderSelector,
  (order) => order.errors,
);
export const statesSelector = createSelector(
  orderSelector,
  (order) => order.states,
);
export const shipAddressSelector = createSelector(
  orderSelector,
  (order) => order.ship_address,
);
export const shippingMethodsSelector = createSelector(
  orderSelector,
  (order) => order.shipping_methods,
);
export const freeSamplesSelector = createSelector(
  orderSelector,
  (order) => order.free_samples,
);
export const shippingPriceSelector = createSelector(
  orderSelector,
  (order) => Number(order.shipping_price),
);
export const itemTotalSelector = createSelector(
  orderSelector,
  (order) => order.display_item_total,
);
export const orderTotalSelector = createSelector(
  orderSelector,
  (order) => Number(order.total),
);
export const orderIdSelector = createSelector(
  orderSelector,
  (order) => order.id,
);
export const orderLoadingSelector = createSelector(
  orderSelector,
  (order) => order.loading,
);
export const guestTokenSelector = createSelector(
  orderSelector,
  (order) => order.token,
);
export const orderNumberSelector = createSelector(
  orderSelector,
  (order) => order.number,
);
export const promotionsSelector = createSelector(
  orderSelector,
  (order) => order.promotions,
);
export const taxesSelector = createSelector(
  orderSelector,
  (order) => order.taxes,
);
export const quadpayMinAmountSelector = createSelector(
  orderSelector,
  (order) => Number(order.quadpay_min_amount),
);
export const stripeApiKeySelector = createSelector(
  orderSelector,
  (order) => order.stripe_api_key,
);
export const availableStoreCreditSelector = createSelector(
  orderSelector,
  (order) => Number(order.total_available_store_credit),
);
export const appliedStoreCreditSelector = createSelector(
  orderSelector,
  (order) => Number(order.total_applied_store_credit),
);
export const giftCardOrderSelector = createSelector(
  orderSelector,
  (order) => order.gift_card_order,
);
export const preorderOrderSelector = createSelector(
  orderSelector,
  (order) => order.preorder_order,
);
