import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '../cart/services/store';

const Modals = React.lazy(() => import('./components/modals/Modals'));

export default function renderModals() {
  window.store = window.store || store;

  const element = document.getElementById('react-modals');
  let { embed } = element.dataset;
  embed = JSON.parse(embed);

  ReactDOM.render(
    <Suspense fallback="">
      <Provider store={window.store}>
        <Modals embedApp={embed} />
      </Provider>
    </Suspense>,
    element,
  );
}
