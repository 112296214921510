import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadable from '../../../loadable';
import Icon from './icon/Icon';
import { searchActions } from '../../../cart/actions';
import { searchOpenedSelector } from '../../selectors/search';
import styles from './Search.module.sass';

const SearchWrapper = loadable(() => import('./search_wrapper/SearchWrapper'));

function Search(props) {
  const { searchOpened, openSearch, closeSearch } = props;

  return (
    <div className={styles.search}>
      <Icon onClick={openSearch} />
      { searchOpened && (
        <SearchWrapper closeSearchWrapper={closeSearch} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  searchOpened: searchOpenedSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  openSearch: () => dispatch(searchActions.openSearch()),
  closeSearch: () => dispatch(searchActions.closeSearch()),
});

Search.propTypes = {
  searchOpened: PropTypes.bool.isRequired,
  openSearch: PropTypes.func.isRequired,
  closeSearch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
